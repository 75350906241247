import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

//获取用户分页列表
export function getUserList(params) {
  return request({
    url: envInfo.bgApp.ssoPath + '/user/list',
    method: 'get',
    params
  })
}

//获取所有用户
export function getUserInfoList() {
  return request({
    url: envInfo.bgApp.ssoPath + '/user/userList',
    method: 'get',
  })
}

//保存用户
export function saveUser(params) {
  return request({
    url: envInfo.bgApp.ssoPath + '/user/create',
    method: 'post',
    data: params
  })
}

//删除用户
export function deleteUser(params) {
  return request({
    url: envInfo.bgApp.ssoPath + '/user/delete/'+params.userId,
    method: 'delete'
  })
}

//更新用户
export function updateUser(params) {
  return request({
    url: envInfo.bgApp.ssoPath + '/user/update',
    method: 'put',
    data: params
  })
}

//获取token
export function getToken(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/token/getToken',
    method: 'get',
    params
  })
}

//重置token
export function resetToken(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/token/resetToken',
    method: 'post',
    data: params
  })
}

//验证token
export function validToken(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/token/checkToken',
    method: 'post',
    data: params
  })
}

//获取token
export function _tenant_getToken(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/token/getToken',
    method: 'get',
    params
  })
}

//重置token
export function _tenant_resetToken(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/token/resetToken',
    method: 'post',
    data: params
  })
}

//验证token
export function _tenant_validToken(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/token/checkToken',
    method: 'post',
    data: params
  })
}

/**
 * 查询手机区号
 * @param params
 */
export function findPhoneZoneCode(params) {
  return request({
    url: envInfo.bgApp.lookupPath + `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
    method: 'get',
  })
}
