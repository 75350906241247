/**
 * 判断是否是合法的账号
 * @param ua
 * @returns {boolean}
 */
export function isValidUserAccount(ua) {
  const uaregex = /^[a-z,A-Z]+[0-9,a-z,A-Z]*$/;
  return uaregex.test(ua);
}

/**
 * 验证中国大陆电话
 * @param {string} str
 */
export function validCNPhone(str) {
  const regex =
    /^((00|\+)?(86(?:-| )))?((\d{11})|(\d{3}[- ]{1}\d{4}[- ]{1}\d{4})|((\d{2,4}[- ]){1}(\d{7,8}|(\d{3,4}[- ]{1}\d{4}))([- ]{1}\d{1,4})?))$/;
  return regex.test(str);
}

/**
 * 验证中国香港电话
 * @param {string} str
 */
export function validHKPhone(str) {
  // const regex = /^([6|9])\d{7}$/;
  const regex = /^\d{8}$/;
  return regex.test(str);
}

/* 合法uri*/
export function validateURL(textval) {
  const urlregex =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return urlregex.test(textval);
}

/* 合法email*/
export function validateEmail(textval) {
  const urlregex = /^(.+)@(.+)$/;
  return urlregex.test(textval);
}

/* 小写字母*/
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}

/* 大写字母*/
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

/* 大小写字母*/
export function validatAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

export function validateNumber(str) {
  const reg = /^\d+$/;
  return reg.test(str);
}

export function validateCaptcha(str, len) {
  const reg = /^\d+$/;
  return reg.test(str) && str.length == len;
}
