<template>
    <div>
        <el-form label-width="60px" :model="tenant.form" ref="tenantForm" :rules="tenant.formRule">
            <el-row>
                <el-col :span="24">
                    <el-form-item :label="$t('userManager.userAccount')" prop="userAccount">
                        <account-select v-model="tenant.form.userAccount" model="1" @change="selectAccount"></account-select>
                      <el-col :span="6" :offset="6"><div class="grid-content bg-purple"></div></el-col>
                    </el-form-item>
                  <span class="copy-container"  @click="regist">
                        无此账号？
                  </span>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" align="right" >
            <el-button @click="cancel()">{{$t('commons.cancel') }}</el-button>
            <el-button type="primary" @click="allotAccount()">{{$t('commons.save') }}</el-button>
        </div>

      <!-- 注册用户 -->
      <el-dialog :visible.sync="registModel" append-to-body width="30%" :before-close="cancelAddUser">
        <template slot="title">
          <title-icon/>{{ $t('userManager.regist') }}
        </template>
        <el-form label-position="top" :model="user" ref="userForm" :rules="formRule">
          <el-form-item prop="userAccount">
            <el-input v-model="user.userAccount" maxlength="40" :show-word-limit="true" name="userAccount" type="text" auto-complete="on"
                      :placeholder="$t('userManager.userAccount')" autofocus>
              <template slot="prepend">
                <div>
                                <span class="svg-container">
                                <svg-icon icon-class="userAccount"/>
                                </span>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="userName">
            <el-input v-model="user.userName" maxlength="25" :show-word-limit="true" name="userName" type="text" auto-complete="on"
                      :placeholder="$t('userManager.userName')" autofocus>
              <template slot="prepend">
                <div>
                                <span class="svg-container">
                                <svg-icon icon-class="userIcon"/>
                                </span>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input style="width:70%" maxlength="11" :show-word-limit="true" v-model="user.phone" name="phone" type="text" auto-complete="on"
                      :placeholder="$t('userManager.phone')" autofocus>
              <template slot="prepend">
                <div>
                                <span class="svg-container">
                                    <svg-icon icon-class="phoneIcon"/>
                                </span>
                </div>
              </template>
            </el-input>
            <div style="width:30%; float:left">
              <el-select v-model="user.zoneCode">
                <template v-for="item in zoneCodeList ">
                  <el-option :key="item.itemId" :label="item.itemName" :value="item.itemCode"></el-option>
                </template>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="email">
            <el-input v-model="user.email" maxlength="33" :show-word-limit="true" name="email" type="text" auto-complete="on"
                      :placeholder="$t('userManager.email')" autofocus>
              <template slot="prepend">
                <div>
                                <span class="svg-container">
                                    <svg-icon icon-class="emailIcon"/>
                                </span>
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="cancelAddUser()">{{$t('commons.cancel') }}</el-button>
          <el-button type="primary" @click="saveUser()" :loading="registLoading">{{$t('commons.save') }}</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import {tenantRegisterUser} from "../../../../api/business/sso/manager/user";

let manager1 = require("@/api/ruge/tenant/tenant.js")
let manager = require("@/api/business/sso/manager/user.js")
import AccountSelect from '@/components/AccountSelect'
import {isValidUserAccount, validCNPhone, validateEmail} from '@/utils/validate'


export default {
    components: {
        AccountSelect
    },
    data() {
        return {
          user: {
            userAccount: null,
            userName: null,
            zoneCode: '86',
            phone: null,
            email: null
          },
          zoneCodeList: [],
          formRule: {
            userAccount: [{
              required: true,
              trigger: ['blur', 'change'],
              message: this.$t('validate.required')
            }, {
              validator : (rule, value, callback) =>{
                if(value && value.length > 60) {
                  callback(new Error(this.$t('userManager.validMessage.userAccountOutLen')));
                }
                // if(!isValidUserAccount(value)) {
                //   callback(new Error(this.$t('userManager.validMessage.userAccountRegx')));
                // }
                callback();
              },
              trigger: ['blur', 'change']
            }],
            userName: [{
              required: true,
              trigger: ['blur', 'change'],
              message: this.$t('validate.required')
            }],
            phone: [{
              required: true,
              trigger: ['blur', 'change'],
              message: this.$t('validate.required')
            },{
              validator: (rule, value, callback) =>{
                if(value && !validCNPhone(value)) {
                  callback(new Error(this.$t('userManager.validMessage.phoneRegx')));
                }
                callback();
              },
              trigger: ['blur', 'change']
            }],
            email: [{
              required: false,
              trigger: ['blur', 'change']
            },{
              validator: (rule, value, callback) =>{
                if(value && !validateEmail(value)) {
                  callback(new Error(this.$t('validate.invalidEmail')));
                }
                callback();
              },
              trigger: ['blur', 'change']
            }]
          },
          registModel: false,
          registLoading: false,
            tenant: {
                form: {
                    userAccount:null,
                    userId: null
                },
              formRule: {
                userAccount : [{
                  required: true,
                  trigger: ['blur', 'change'],
                  message: this.$t('validate.required')
                }],
                tenantId : [{
                  required: true,
                  trigger: ['blur', 'change'],
                  message: this.$t('validate.required')
                }]
              }
            }
        }
    },
    methods: {
      initZoneCodeList() {
        manager.findPhoneZoneCode({classifyCode: 'PHONE_ZONE_CODE'}).then(r=> {
          this.zoneCodeList = r
        })
      },
      // 设置日期范围查询条件
      regist() {
        this.user = {
          userAccount: null,
          userName: null,
          zoneCode: '86',
          phone: null,
          email: null
        };
        this.registModel = true;
        this.initZoneCodeList();
      },
      cancelAddUser() {
        this.$refs.userForm.resetFields();
        this.registModel = false;
      },
        allotAccount() {
            this.$refs.tenantForm.validate((valid)=>{
                if(valid) {
                  manager1._tenant_mountAccount(this.tenant.form).then((rsp)=>{
                        if(rsp > 0) {
                             this.tenant.form.userAccount = null;
                            this.tenant.form.tenantId = null;
                            this.$message({type: 'success', message: this.$t('message.saveSuccess')});
                            this.cancel(true)
                        } else {
                            this.$message({type: 'error', message: this.$t('tenant.message.existUser')});
                        }
                    })
                }
            })
        },
      saveUser() {
        this.$refs.userForm.validate((valid) => {
          if (valid) {
            this.registLoading = true;
            tenantRegisterUser(this.user).then((r)=> {
              if(r == 1) {
                this.registModel = false;
                this.cancel(true)
              } else if(r ==2) {
                this.$message({type: 'error', message: this.$t('userManager.message.existAccount')});
              } else if(r == 3) {
                this.$message({type: 'error', message: this.$t('userManager.message.illegePhone')});
              } else if(r ==4) {
                this.$message({type: 'error', message: this.$t('userManager.message.noneAccount')});
              } else if(r == 5) {
                this.$message({type: 'error', message: this.$t('userManager.message.existPhone')});
              } else {
                this.$message({type: 'error', message: this.$t('message.unknowException')});
              }
            }).finally(() => {
              this.registLoading = false;
            })
          }
        });
      },
        selectAccount(record) {
            this.tenant.form.userId = record.userId
        },
        cancel(cbk) {
            this.$refs.tenantForm.resetFields();
            this.$emit("cancle", cbk);
        }
    }
}
</script>

<style scoped lang="less">
.copy-container {
  float: right;
  margin: 0 5px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  cursor: pointer;
  color: #0486fe;
  height:30px;
  &:hover {
    color: #0f2fd5;
  }
}
</style>