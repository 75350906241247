import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'
import {downTemplate} from '@/utils/download'

//获取用户分页列表
export function getUserList(params) {
    return request({
      url: envInfo.bgApp.ssoPath + '/sso/manager/list',
      method: 'get',
      params
    })
}

//注册用户
export function registUser(params) {
    return request({
      url: envInfo.bgApp.ssoPath + '/sso/manager/createUser',
      method: 'post',
      data: params
    })
}

//租户注册用户
export function tenantRegisterUser(params) {
    return request({
        url: envInfo.bgApp.ssoPath + '/sso/user/tenantRegisterUser',
        method: 'post',
        data: params
    })
}

// //删除用户
// export function deleteUser(params) {
//     return request({
//       url: envInfo.bgApp.ssoPath + "/sso/manager/delete/"+params.userAccount,
//       method: 'delete'
//     })
// }

//切换账号状态
export function switchAccountStatus(params) {
  return request({
    url: envInfo.bgApp.ssoPath + "/sso/manager/switchStatus",
    method: 'post',
    data: params
  })
}

//更新
export function editUser(params) {
    return request({
      url: envInfo.bgApp.ssoPath + '/sso/manager/updateUser',
      method: 'post',
      data: params
    })
}

//重置密码
export function resetPwd(params) {
    return request({
      url: envInfo.bgApp.ssoPath + '/sso/manager/resetPassword',//{userAccount:userAccount}
      method: 'post',
      data: params
    })
}

//下载用户模版
export function downUserTemplate() {
   downTemplate("ruge.ssoUser", envInfo.bgApp.ssoPath);
}

//导出用户
export function exportUser(params) {
  return request({
    url: envInfo.bgApp.ssoPath + '/sso/manager/export',//{userAccount:userAccount}
    method: 'post',
    data: params
  })
}

/**
 * 查询手机区号
 * @param params
 */
export function findPhoneZoneCode(params) {
    return request({
        url: envInfo.bgApp.lookupPath + `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
        method: 'get',
    })
}
